import React from 'react';
import 'bootstrap/dist/css/bootstrap-reboot.css'
import 'bootstrap/dist/css/bootstrap-grid.css'
import Home from "./app/pages/home"

function App() {
    return (
        <Home />
    );
}

export default App;
