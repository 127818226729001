import React from "react";
import {Div, Heading, Link, Text} from "../../styles/base.style";
import {FooterSectionWrap} from "./footerSection.style";
import ScrollAnimation from "react-animate-on-scroll";

const FooterSection = ({...props}) => {
    // console.log(props);
    const purchase_link = props.purchase_link ? props.purchase_link : '#';
    return (
        <FooterSectionWrap id="ftss">
            <Div className="container-fluid" maxWidth={1300} pb={80}>
                <Div className="row">
                    <Div className="col-md-6">
                        <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                            <Text fontWeight={500} fontStyle={'italic'} fontSize={25} color={'#FEFDFD'} mb={20}>There is no limit to what you can create</Text>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={200}>
                            <Heading fontSize={40} color={'#FFFEFE'} mb={20}>Build your website today!</Heading>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={400}>
                            <Text fontWeight={500} fontSize={25} color={'#EAEAEA'} mb={56}>Your business & corporation project will start getting money and move your activities to
        much higher level. Good luck to you!</Text>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={500}>
                            <Link href={purchase_link} className={'bn-btn'}>Purchase now</Link>
                        </ScrollAnimation>
                    </Div>
                    <Div className="col-md-6" />
                </Div>
            </Div>
            <Div className="container-full" background={'#221E1E'} p={'50px 0 50px'}>
                <Div className="row">
                    <Div className="col-md-12 text-center">
                        <Text as={'span'} fontSize={25} color={'white'}>© {new Date().getFullYear()} Doyle. All Rights Reserved . Copyright by Bearsthemes</Text>
                    </Div>
                </Div>
            </Div>
        </FooterSectionWrap>
    )
};

export default FooterSection;
