import React from "react";
import Swiper from 'react-id-swiper';
import 'react-id-swiper/lib/styles/scss/swiper.scss';
import {Div, GlobalHeading, Heading, Img, Text} from "../../styles/base.style";
import {TestimonialItem, TestimonialWrap} from "./testimonial.style";
import MAN from '../../images/man.png'
import Star from '../../images/star.svg'
import Client from '../../images/envato_logo.svg'
import ScrollAnimation from "react-animate-on-scroll";

const params = {
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
    }
};

const Testimonial = ({...props}) => {
    const testimonials = props.testimonials ? props.testimonials : [];
    return (
        <TestimonialWrap>
        <Div className="container-fluid" maxWidth={1300}>
            <Div className="row">
                <Div className="col-md-12 text-center">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <GlobalHeading>Testimonial</GlobalHeading>
                    </ScrollAnimation>
                </Div>
            </Div>
            <Div className="row" mt={50} alignItems={[ 'flex-end', '', '', 'center']}>
                <Div className="col-lg-8">
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    {testimonials.length ?
                        <Swiper {...params}>
                            {
                                testimonials.map((tes, index) => (
                                    <Div className="t-item-wrap" key={'tes-' + index}>
                                        <TestimonialItem>
                                            <Div className="t-header">
                                                <Img className="t-logo" src={tes.logo ? tes.logo : Client} alt="logo" />
                                                <Div className="t-meta">
                                                    <Heading>{tes.name}</Heading>
                                                    <Text as="span">
                                                        <Img src={Star} alt="star" />
                                                        <Img src={Star} alt="star" />
                                                        <Img src={Star} alt="star" />
                                                        <Img src={Star} alt="star" />
                                                        <Img src={Star} alt="star" />
                                                    </Text>
                                                </Div>
                                            </Div>
                                            <Heading as="h4" className="t-position">{tes.po}</Heading>
                                            <Div className="t-des">{tes.review}</Div>
                                        </TestimonialItem>
                                    </Div>
                                ))
                            }
                        </Swiper> :
                        <Div>loading data...</Div>
                    }
                    </ScrollAnimation>
                </Div>
                <Div className="col-lg-4">
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={500}>
                    <Div display={['none', '', '','block']}>
                        <Img src={MAN} alt={'man'} />
                    </Div>
                    </ScrollAnimation>
                </Div>
            </Div>
        </Div>
    </TestimonialWrap>
    )
};

export default Testimonial;
