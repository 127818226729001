import React from "react";
import {Div, Img, Link} from "../../styles/base.style";
import Banner from "../BannerSection";
import LOGO from '../../images/logo.svg'
import {HeaderWrap} from "./headerFixed.style";


const HeaderFixed = ({...props}) => {
    const doc_link = props.doc_link ? props.doc_link : '#';
    const sp_link = props.sp_link ? props.sp_link : '#';

    const scrollIt = element => {
        window.scrollTo({
            'behavior': 'smooth',
            'left': 0,
            'top': element.offsetTop
        });
    };

    return (
        <HeaderWrap>
            <Div className="container">
                <Div className="row">
                    <Div className="col-md-12 d-flex justify-content-between align-items-center">
                        <Div className="logo">
                            <Link className={'menu-item'} href={'/'}>
                            <Img src={LOGO} alt={'logo'} />
                            </Link>
                        </Div>
                        <Div className="menu">
                            <Link className={'menu-item'} href={'#prebuild-demo'} onClick={(e) => {
                                e.preventDefault();
                                scrollIt(document.getElementById('prebuild-demo'));
                            }}>Demo</Link>
                            <Link className={'menu-item'} href={doc_link} target="_blank">Documentation</Link>
                            <Link className={'menu-item'} href={sp_link} target="_blank">Support</Link>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </HeaderWrap>
    )
};

Banner.propTypes = {};
Banner.defaultProps = {};

export default HeaderFixed;
