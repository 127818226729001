import {Div, Heading, Img, Link, Text} from "../../styles/base.style";
import React, {Component} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {AlsoLikeContentCarouselWrap, AlsoLikeWrap} from "../AlsoLikeSection/alsoLike.style";
import Swiper from "react-id-swiper";

const params = {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 20,
    slidesPerGroup: 1,
    loopFillGroupWithBlank: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    breakpointsInverse: true,
    breakpoints: {
        576: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        991: {
            slidesPerView: 3,
            slidesPerGroup: 2,
        },
        1024: {
            slidesPerView: 4,
            slidesPerGroup: 2,
        },
        1200: {
            slidesPerView: 5,
            slidesPerGroup: 2,
        },
    }
};

class AlsoLikeCarousel extends Component<{}> {
    render() {
        let {alsoLike, text_also_subtitle} = this.props;
        return (
            <AlsoLikeWrap id="also-like" className="also-like-carousel">
                <Div className="container-full">
                    <Div className="row">
                        <Div className="col-md-12 text-center">
                            {text_also_subtitle ?
                                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                                    <Text mb={45} className="text-below-global-heading">{text_also_subtitle}</Text>
                                </ScrollAnimation> : ''}
                        </Div>
                    </Div>

                    <Div className="row">
                        <Div className="col-md-12">
                            {alsoLike.length ?
                                <AlsoLikeContentCarouselWrap>
                                <Swiper {...params}>
                                    {
                                        alsoLike.map((item, index) => (
                                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={200 * index} key={'also-like-' + index}>
                                            <Div className="also-inner-wrap">
                                                <Link className="img-wrap" target="_blank" href={item.link} title={item.title}>
                                                    <Img src={item.img + '?' + index} alt={item.title} />
                                                    <Heading className="name-also-like-home">{item.title}</Heading>
                                                </Link>
                                                <Link className="btn-discover" target="_blank" href={item.link} title="Discover Now">Discover Now</Link>
                                            </Div>
                                            </ScrollAnimation>
                                        ))
                                    }
                                </Swiper>
                                </AlsoLikeContentCarouselWrap> :
                                <Div className="text-center">loading data...</Div>
                            }
                        </Div>
                    </Div>
                </Div>
            </AlsoLikeWrap>
        )
    }
}

export default AlsoLikeCarousel
