import styled from "styled-components";
import base from "../../styles/base.style";

export const HeaderWrap = styled('header')`
${base};
background: transparent;
padding: 23px 0 15px ;
position: absolute;
width: 100%;
top:0;
z-index: 9;
.logo{
    width: 114px;
}

.menu{
    @media (max-width: 480px){
        display: none;
    }
    .menu-item{ 
        font-weight: 500;
        font-size: 16px;
        display: inline-block;
        line-height: 1;
        color: #FFFFFF;
        padding-left: 30px;
        margin-left: 30px;
        text-decoration: none;
        transition: .3s;
        border-left: 1px solid rgba(255,255,255,0.5);
        &:first-child{
            border: none;
            padding: 0;
            margin: 0;
        }
        &:hover{
            color: var(--second-color);
        }
        @media (max-width: 767px){
        }
    }
}
`;
