import React, {Component} from 'react';
import {ThemeProvider} from 'styled-components'
import {GlobalStyle,} from "../styles/base.style";
import {Wrapper} from "../styles/home.style";
import BannerSection from "../components/BannerSection";
import CoreFeatures from "../components/CoreFeatures";
import API from "../services/api";
import PrebuildDemoSection from "../components/PrebuildDemoSection";
import HeaderFixed from "../components/HeaderFixed";
import PremiumPlugins from "../components/PremiumPlugins";
import FooterSection from "../components/FooterSection";
import Testimonial from "../components/Testimonial";
import {Json_Demo} from "./datas/Home";
import AlsoLike from "../components/AlsoLikeSection";
import AlsoLikeCarousel from "../components/AlsoLikeCarouselSection";

const LandingAPI = new API('doyle/');

const breakpoints = ['576px', '768px', '992px', '1200px'];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

class Home extends Component<{}> {
    constructor(props) {
        super(props);
        this.state = {
            prebuild_demo_layout: 'layout-3-col',
            general: {},
            disable_filter: false,
            catFilter: {},
            homePageDemo: [],
            homePageTemp: [],
            testimonials: [],
            also_likes_layout: 'grid',
            also_likes: [],
            isLoading: true,
            error: null,
            textEditor: {}
        };
        // console.log(this.state);
    }

    componentDidMount() {
        LandingAPI.getData().then((data) => {
            // console.log('request succeeded with JSON response', data);
            this.setState({
                prebuild_demo_layout: data.response.prebuild_demo_layout,
                general: data.response.general,
                disable_filter: data.response.disable_filter ? data.response.disable_filter : false,
                catFilter: data.response.cat_filter,
                homePageDemo: data.response.demo_page,
                homePageTemp: data.response.demo_page,
                testimonials: data.response.testimonials,
                also_likes: data.response.also_likes,
                also_likes_layout: data.response.also_likes_layout,
                textEditor: data.response.text_editor,
                isLoading: false,
            });
            const ele = document.getElementById('bt-loader');
            if (ele) {
                ele.classList.add('available');
            }
        }).catch((error) => {
            console.log('request failed', error);
            let data = Json_Demo;
            this.setState({
                prebuild_demo_layout: data.response.prebuild_demo_layout,
                general: data.response.general,
                catFilter: data.response.cat_filter,
                homePageDemo: data.response.demo_page,
                homePageTemp: data.response.demo_page,
                testimonials: data.response.testimonials,
                also_likes: data.response.also_likes,
                textEditor: data.response.text_editor,
                isLoading: false,
            });
            // this.setState({error: 'Load data fail!', isLoading: true});
            const ele = document.getElementById('bt-loader');
            if (ele) {
                ele.classList.add('available');
            }
        })
    }

    componentWillUnmount() {

    }

    componentDidUpdate() {
    }

    render() {
        // console.log(this.state);
        const {purchase_link, document_link, support_link} = this.state.general;

        return (
            <ThemeProvider theme={{
                fontFamily: '"Roboto", sans-serif',
                breakpoints: breakpoints,
                colors: {'accentColor': '#00c282', 'secondColor': '#F7951D',}
            }}>
                <React.Fragment>
                    <GlobalStyle />
                    <Wrapper>
                        <HeaderFixed doc_link={document_link} sp_link={support_link} />
                        <BannerSection purchase_link={purchase_link} {...this.state.textEditor} />
                        {
                            (this.state.also_likes_layout === 'carousel') ?
                                <AlsoLikeCarousel {...this.state.textEditor} alsoLike={this.state.also_likes} /> : ''
                        }

                        <PrebuildDemoSection {...this.state} />
                        {
                            (this.state.also_likes_layout === 'grid') ?
                                <AlsoLike alsoLike={this.state.also_likes} /> : ''
                        }
                        <PremiumPlugins />
                        <CoreFeatures />
                        <Testimonial testimonials={this.state.testimonials} />
                        <FooterSection purchase_link={purchase_link} />
                    </Wrapper>
                </React.Fragment>
            </ThemeProvider>
        )
    }
}

export default Home;
