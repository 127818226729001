import styled from "styled-components";
import base from "../../styles/base.style";

export const AlsoLikeWrap = styled('section')`
    ${base};
    background: #F7F7F8;static
android-icon-36x36.png
android-icon-48x48.png
android-icon-72x72.png
android-icon-96x96.png
android-icon-144x144.png
android-icon-192x192.png
apple-icon.png
apple-icon-57x57.png
apple-icon-60x60.png
apple-icon-72x72.png
apple-icon-76x76.png
apple-icon-114x114.png
apple-icon-120x120.png
apple-icon-144x144.png
apple-icon-152x152.png
apple-icon-180x180.png
apple-icon-precomposed.png
asset-manifest.json
browserconfig.xml
favicon.ico
favicon-16x16.png
favicon-32x32.png
favicon-96x96.png
index.html
manifest.json
ms-icon-70x70.png
ms-icon-144x144.png
ms-icon-150x150.png
ms-icon-310x310.png
precache-manifest.3287e73a4c0e6f42f74358720bc83aa4.js
screenshot.jpg
service-worker.js
    padding: 106px 0 115px;
    &.also-like-carousel{
        background: white;
        padding: 0 ;
        .swiper-container{
            padding-bottom: 50px;
            .swiper-button-next, .swiper-container-rtl .swiper-button-prev{
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
            }
            .swiper-button-prev, .swiper-container-rtl .swiper-button-next{
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
            }
            .swiper-button-prev, 
            .swiper-button-next{
                top: 40%;
                opacity: 0;
                visibility: hidden;
                transition: .4s;
                &.swiper-button-disabled{
                    pointer-events: auto;
                    cursor: not-allowed;
                }
            }
            &:hover{
                .swiper-button-prev, 
                .swiper-button-next{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
`
;

export const AlsoLikeContentWrap = styled('div')`
    ${base};
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    margin-top: 100px;
    @media (max-width: 991px){
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 600px){
        grid-template-columns: repeat(1, 1fr);
    }
    .also-inner-wrap{
        position: relative;
        &:hover{
            .btn-discover{
                transform: translate(-50%, -50%);
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .btn-discover{
        font-size: 20px;
        line-height: 1;
        color: #FFFFFF;
        display: flex;
        padding: 15px 30px;
        background: var(--theme-color);
        
        border-radius: 30px;
        text-decoration: none;
        transition: .3s;
        position: absolute;
        top: 50%;
        left: 50%;
        white-space: nowrap;
        transform: translate(-50%, 50%);
        opacity: 0;
        visibility: hidden;
    }
    a{
        text-decoration: none;
    }
    img{
        box-shadow: 0 4px 11px rgba(0, 0, 0, 0.14);
        margin-bottom: 30px;
    }
    .name-also-like-home{
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: normal;
        text-align: center; 
        color: #000000;
        transition: .3s;
        &:hover{
            color: var(--theme-color);
        }
    }
`
;

export const AlsoLikeContentCarouselWrap = styled('div')`
    ${base};
    .also-inner-wrap{
        a.img-wrap{
            display: block;
            height: 320px;
            overflow: hidden;
            border-radius: 8px;
            position: relative;
            color: white;
            &:before{
                content: '';
                width: 100%;
                height: 100%;
                background: transparent;
                top: 0;
                left: 0;
                position: absolute;
                transition: .4s;
            }
        }
        .name-also-like-home {
            position: absolute;
            bottom: 20px;
            left: 20px;
            margin: 0;
            max-width: calc(100% - 40px); 
            
            background: #4776e6;
            border-radius: 6px;
            padding: 6px 15px;
            box-sizing: border-box;
            font-size: 18px;
            transition: .4s;
        }
        .btn-discover{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
            color: white;
            background: var(--theme-color);
            border-radius: 30px;
            padding: 8px 20px;
            font-size: 20px;
            box-sizing: border-box;
            white-space: nowrap;
            text-decoration: none;
            opacity: 0;
            visibility: hidden;
            transition: .4s;
        }
        &:hover{
            .btn-discover{
                transform: translate(-50%, -50%);
                opacity: 1;
                visibility: visible;
            }
            a.img-wrap{
                &:before{
                    background: rgba(0, 0, 0, 0.4);
                }
            }
            .name-also-like-home{
                transform: translateY(calc(100% + 20px));
            }
        }
    }
`
;





