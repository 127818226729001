import styled from "styled-components";
import base from "../../styles/base.style";

const HomeDemoMetaWrap = styled('div')(base,
    `     
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    transition: .4s;
    padding: 20px; 
    opacity: 0;
    visibility: hidden;
    .demo-link{
        background: var(--theme-color);
        padding: 10px 20px;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        text-decoration: none;
        display: inline-flex;
    }
    `
);
const HomeDemoWrap = styled('div')(base,
    `     
    position: relative;
    overflow: hidden;
    box-shadow: 1px 5px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    height: 70vh;
    max-height: 450px;
    background: rgba(0,0,0,0.25);
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: transparent;
        transition: .4s;
    }
    &:hover{
        ${HomeDemoMetaWrap}{
            top: 50%;
            opacity: 1;
            visibility: visible;
        }
        &:before{ 
            background: rgba(1, 1, 1, 0.64);
        }
    }
    `
);
const FilterWrap = styled('div')`
    ${base};
    .filter-nav{ 
        font-size: 18px;
        text-decoration: none;  
        color: black;
        padding: 16px 40px;
        line-height: 1;
        background: #ECEAEA;
        border-radius: 25px; 
        font-weight: normal;
        transition: .4s;
        min-width: 150px;
        text-align: center;
        margin: 0 5px 10px;
        &:hover,
        &.active{
            background: var(--theme-color);
            color: white;
        }
    }
`;
const ImgWrap = styled('div')(base,
    `height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    `
);
const HomepageDemoContainer = styled('div')`
    ${base};
    &.layout-4-col{ 
        @media screen and (min-width: 1024px){
            grid-template-columns: 1fr 1fr 1fr 1fr; 
            grid-gap: 50px 35px;
        }
        ${HomeDemoWrap}{ 
            height: auto;
            max-height: 850px;
            box-shadow: none;
            overflow: visible;
            background: transparent;
            &:before{
                content: unset;
            }
            ${ImgWrap}{
                height: calc(100% - 120px);
                box-shadow: 0px 19px 16px 0px rgba(24,24,40,0.4);    
                border-radius: 3px;
                overflow: hidden;
                position: relative;
                a{
                display: block;
                }
                
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(80, 94, 216, 0.5);
                    top: 0;
                    left: 0;
                    opacity: 0; 
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
                }
            }
            ${HomeDemoMetaWrap}{
                position: relative;
                top: unset;
                left: unset;
                transform: none;
                opacity: 1;
                visibility: visible;
                padding: 40px 20px 0;
                .home-title{
                    color: black;
                    margin-bottom: 0;
                    position: relative;
                    display: inline-block;
                    font-size: 18px;
                    &:after {
                        content: '';
                        height: 1px;
                        width: 50px;
                        background: #505ed8;
                        position: absolute;
                        bottom: -2px;
                        left: 50%;
                        transform: translateX(-50%);
                        transition: all 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
                    }
                }
                .demo-link{
                    display: none;
                }
            }
            &:hover{
                ${ImgWrap}{
                    &:after { 
                        opacity: 1; 
                        visibility: visible; 
                    }
                } 
                ${HomeDemoMetaWrap}{ 
                    .home-title{ 
                        &:after {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.layout-3-col-full{
        ${HomeDemoWrap}{
            overflow: unset;
            box-shadow: none;
            cursor: pointer;
            height: auto;
            max-height: none;
            background: transparent;
            &:before{
                content: unset;
            }
        }
        ${ImgWrap}{
            height: 320px; 
            overflow: hidden;
            position: relative;
            box-shadow: 0 8px 11px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            transition: .3s;
            img{
                width: 100%;
                height: auto;
                object-fit: unset;
            }
            a{
                display: block;
            } 
        }
        ${HomeDemoMetaWrap}{
            position: relative;
            top: unset;
            left: unset;
            transform: none;
            opacity: 1;
            visibility: visible;
            padding: 20px 20px 0;
            .home-title{
                color: black;
                margin-bottom: 0;
                position: relative;
                display: inline-block;
                font-size: 18px;
                &:after {
                    content: '';
                    height: 1px;
                    width: 50px;
                    background: #505ed8;
                    position: absolute;
                    bottom: -2px;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: all 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
                }
            }
            .demo-link{
                display: none;
            }
        }
        ${HomeDemoWrap}{
            &:hover{
                ${ImgWrap}{
                    transform: translateY(-15px);    
                    box-shadow: 0 29px 40px rgba(0,0,0,.5);
                }
            }
        }
    }
    `
;
export {HomepageDemoContainer, HomeDemoWrap, HomeDemoMetaWrap, FilterWrap, ImgWrap}
