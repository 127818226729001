import {Div, GlobalHeading, Heading, Img, Text} from "../../styles/base.style";
import React from "react";
import {Special_Data} from "../../pages/datas/Home";
import PropTypes from "prop-types";
import {CoreFeaturesWrap, SpecialItem} from "./coreFeatures.style";
import ScrollAnimation from "react-animate-on-scroll";

const CoreFeatures = ({specialItem}) => {
    return (
        <CoreFeaturesWrap id="special" pt={70} pb={[100,150,200]}>
            <Div className="container-fluid" maxWidth={1300}>
                <Div className="row">
                    <Div className="col-md-8 offset-md-2 text-center">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <GlobalHeading>Core Features</GlobalHeading>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                        <Text className="text-below-global-heading">Doyle Business Consulting Theme Have Unlimited Features</Text>
                    </ScrollAnimation>
                    </Div>
                </Div>
                <Div className="row">
                    <Div className="col-md-12">
                        <Div className="special-wrap" mt={95} display={'grid'} gridTemplateColumns={['1fr', 'repeat(2, 1fr)', '', 'repeat(4, 1fr)']} gridGap={['25px 24px','50px 24px']}>
                            {Special_Data.map((item, index) => (
                                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={150 * index} key={`special-${index}`} >
                                    <SpecialItem {...specialItem} className="special-item text-center" title={item.title}>
                                        <Div mb={'36px'}><Img src={item.icon} alt={item.title} /></Div>
                                        <Heading className="title" mb={20} fontWeight={400} fontSize={20} lineHeight={'normal'} color={'black'} as={'h3'}>{item.title}</Heading>
                                        <Div className="des" fontSize={18} lineHeight={'normal'} color={'#A89D9D'}>{item.des}</Div>
                                    </SpecialItem>
                                </ScrollAnimation>
                                )
                            )}
                        </Div>
                    </Div>
                </Div>
            </Div>
        </CoreFeaturesWrap>
    );
};


CoreFeatures.propTypes = {
    specialItem: PropTypes.object,
};
CoreFeatures.defaultProps = {
    specialItem: {
        bg: '#FFFFFF',
        boxShadow: '0px 4px 22px rgba(0, 0, 0, 0.25)',
        p: '40px 30px 20px',
        border: '1px solid transparent',
    }
};
export default CoreFeatures
