import {Div, GlobalHeading, Heading, Img, Link, Text} from "../../styles/base.style";
import React, {Component} from "react";
import {AlsoLikeContentWrap, AlsoLikeWrap} from "./alsoLike.style";
import ScrollAnimation from "react-animate-on-scroll";

class AlsoLike extends Component<{}> {
    render() {
        let {alsoLike} = this.props;
        return (
            <AlsoLikeWrap id="also-like">
                <Div className="container-full">
                    <Div className="row">
                        <Div className="col-md-12 text-center">
                            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                <GlobalHeading>You May Also Like</GlobalHeading>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                                <Text className="text-below-global-heading">Premium WordPress Themes from ThemeForest’s global community of web designers.</Text>
                            </ScrollAnimation>
                        </Div>
                    </Div>

                    <Div className="row">
                        <Div className="col-md-12">
                            {alsoLike.length ?
                                <AlsoLikeContentWrap>
                                    {
                                        alsoLike.map((item, index) => (
                                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={200 * index} key={'also-like-' + index}>
                                            <Div className="also-inner-wrap">
                                                <Link href={item.link} title={item.title}>
                                                    <Img src={item.img + '?' + index} alt={item.title} />
                                                    <Heading className="name-also-like-home">{item.title}</Heading>
                                                </Link>
                                                <Link className="btn-discover" href={item.link} title="Discover Now">Discover Now</Link>
                                            </Div>
                                            </ScrollAnimation>
                                        ))
                                    }
                                </AlsoLikeContentWrap> :
                                <Div className="text-center">loading data...</Div>
                            }
                        </Div>
                    </Div>
                </Div>
            </AlsoLikeWrap>
        )
    }
}

export default AlsoLike
