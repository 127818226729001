import {Div, GlobalHeading, Heading, Img, Text} from "../../styles/base.style";
import React from "react";
import {PremiumPluginsWrap} from "./premiumPlugins.style";
import IMG_PLUGINS from '../../images/plugins.png'

import ICON_VS from '../../images/vs-icon.png'
import ICON_RS from '../../images/revo-icon.png'
import ICON_UA from '../../images/utimate-icon.png'
import ICON_ES from '../../images/es-icon.png'
import ScrollAnimation from "react-animate-on-scroll";

const PLUGINS = [
    {
        icon: ICON_VS,
        title: 'VISUAL COMPOSER',
        price: '($46) Free',
        des: 'Number #1. WordPress page builder plugin'
    },
    {
        icon: ICON_RS,
        title: 'SLIDER REVOLUTION',
        price: '($26) Free',
        des: 'Innovative, responsive WordPress slider plugin'
    },
    {
        icon: ICON_UA,
        title: 'UTIMATE ADDONS',
        price: '($26) Free',
        des: 'The #1 Selling Addon for VC'
    },
    {
        icon: ICON_ES,
        title: 'ESSENTIAL GRID GALLERY',
        price: '($27) Free',
        des: 'The all-purpose grid building solution!'
    },
];
const PremiumPlugins = () => (
    <PremiumPluginsWrap id="plugins">
        <Div className="container">
            <Div className="row">
                <Div className="col-md-12 text-center">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <GlobalHeading>Premium plugins</GlobalHeading>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                    <Text className="text-below-global-heading">Purchase Doyle to save your money and use these premium plugins for free</Text>
                    </ScrollAnimation>
                </Div>
            </Div>
            <Div className="row align-items-md-center flex-md-row flex-column-reverse" mt={100}>
                <Div className="col-md-7">
                    <Div className="plugins-grid">
                        {
                            PLUGINS.map((plugin, index) => (
                                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={150 * index} key={'plugin-' + index}>
                                <Div className="pl-item">
                                    <Div className="pl-icon-price">
                                        <Img src={plugin.icon} alt={plugin.title} />
                                        <Text as={'span'}>{plugin.price}</Text>
                                    </Div>
                                    <Heading className="pl-title">{plugin.title}</Heading>
                                    <Text className="pl-des">{plugin.des}</Text>
                                </Div>
                                </ScrollAnimation>
                            ))
                        }
                    </Div>
                </Div>
                <Div className="col-md-5" mb={['30px','','0']}>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={1000}>
                    <Img src={IMG_PLUGINS} alt="plugins" />
                    </ScrollAnimation>
                </Div>
            </Div>
        </Div>
    </PremiumPluginsWrap>
);

export default PremiumPlugins;
