import styled from "styled-components";
import base from "../../styles/base.style";
import BG from '../../images/bg_ft.jpg'

export const FooterSectionWrap = styled('section')`
    ${base};
    padding: 180px 0 0;
    background-image: url("${BG}");
    background-color: #0275E0;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
        @media (max-width: 767px){
            background-size: cover;
            background-position: left;
            padding: 100px 0 0;
        }

    .bn-btn{
        font-size: 25px;
        line-height: 1; 
        color: #FFFFFF;
        display: inline-flex;
        padding: 20px 65px;
        background: var(--theme-color);
        border-radius: 6px;
        text-decoration: none;
        transition: .3s;
        @media (max-width: 1024px){
            font-size: 20px;
        }
        &:hover{
            background: var(--second-color);
        }
    }
`;
