import styled from "styled-components";
import base from "../../styles/base.style";

export const PremiumPluginsWrap = styled('section')`
${base};
padding: 115px 0 40px;
.plugins-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px 20px; 
    @media (max-width: 576px){
        grid-template-columns: 1fr;
        grid-gap: 20px 20px; 
    }
    .pl-item{
        .pl-icon-price{
            display: flex;
            align-items: flex-end;
            margin-bottom: 10px;
            img{
                margin-right: 20px;
                width: 50px;
            }
            span{
                font-size: 25px;
                line-height: normal;
                font-weight: bold;
                font-style: italic;
                color: #040404;
            }
        }
        .pl-title{
            font-size: 25px;
            color: #050505;
            margin-bottom: 15px;
        }
        .pl-des {
            font-size: 18px;
            line-height: 1.56; 
            color: #A99F9F;
        }
    }
}
`;
