import React, {useEffect, useState} from 'react';
import {Div, Heading, Img, Link} from "../../styles/base.style";
// import Particles from "react-particles-js";
// import Best_Sell_Img from '../../images/best-sell.svg'
// import Icon_Purchase from '../../images/shopping-cart.svg'
import Banner_BG from '../../images/banner-bg.png'
import mk_mackbook from '../../images/mockup-mackbook.png'
import logo_d from '../../images/logo-d.png'
import layer_1 from '../../images/layer-1.jpg'
import layer_2 from '../../images/layer-2.jpg'
import layer_3 from '../../images/layer-3.jpg'
import layer_4 from '../../images/layer-4.jpg'
import img_moockup from '../../images/img-moockup.png'
import {BannerWrap} from "./bannerSection.style";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

function getSize() {
    return {
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        outerHeight: window.outerHeight,
        outerWidth: window.outerWidth,
    };
}

function useWindowSize() {
    let [windowSize, setWindowSize] = useState(getSize());

    function handleResize() {
        setWindowSize(getSize());
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
}


const Banner = ({...props}) => {
    // console.log(props);
    const textEditor = props.textEditor ? props.textEditor : {};
    const purchase_link = props.purchase_link ? props.purchase_link : '#';
    const size = useWindowSize();

    const layer = document.querySelectorAll('.layer');
    let initT = [...layer].map(function (el, i) {
            return el.offsetTop;
        }
    );
    window.requestAnimationFrame = window.requestAnimationFrame
        || window.mozRequestAnimationFrame
        || window.webkitRequestAnimationFrame
        || window.msRequestAnimationFrame
        || function (f) {
            setTimeout(f, 1000 / 60)
        };

    function parallaxLayers() {
        let scrolltop = window.pageYOffset;
        layer.forEach(function (currentValue, index, arr) {
            currentValue.style.top = (-scrolltop * .5 + initT[index]) + 'px';
        })
    }

    window.addEventListener('scroll', function () {
        requestAnimationFrame(parallaxLayers)
    }, false);
    return (
        <BannerWrap id="banner" backgroundImage={'url(' + Banner_BG + ')'}>
            <Div className="container">
                <Div className="row">
                    <Div className="col-lg-12">
                        <Div className="bg-banner-inner" mt={100} position="relative">
                            {size.innerWidth > 1200 ? (
                                <>
                                    <Div className="layer layer-1"><Img src={mk_mackbook} alt="mockup mackbook" /></Div>
                                    <Div className="layer logo-d"><Img src={logo_d} alt="logo doyle" /></Div>
                                    <Div className="layer layer-5"><Img src={layer_4} alt="layer 4" /></Div>
                                    <Div className="layer layer-4"><Img src={layer_3} alt="layer 3" /></Div>
                                    <Div className="layer layer-2"><Img src={layer_1} alt="layer 1" /></Div>
                                    <Div className="layer layer-3"><Img src={layer_2} alt="layer 2" /></Div>
                                </>
                            ) : <Img src={img_moockup} alt="bg" />}
                        </Div>
                    </Div>
                </Div>
                <Div className="row" mb={60}>
                    <Div className="col-lg-6 offset-lg-3 text-center">
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={1000}>
                            <Heading className="bn-title">Doyle - True Creative Multipurpose WordPress Theme</Heading>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="tada" animateOnce={true} delay={1200}>
                            <Link className="bn-btn" href={purchase_link}>Get Started Now</Link>
                        </ScrollAnimation>
                    </Div>
                </Div>
                <Div className="row" height={245}>
                    <Div className="col-md-12">
                        <Div className="el-demo-wrap" display={'inline-flex'}>
                            <Div className="element-wrap">
                                <span className="number">145+</span>
                                <span className="text">Custom Elements</span>
                                <div className="el-animation animated infinite pulse" />
                            </Div>
                            <Div className="demo-wrap">
                                <span className="number">11+</span>
                                <span className="text">Unique Demos</span>
                                <div className="el-animation animated infinite pulse" />
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </BannerWrap>
    );
};
// Footer style props
Banner.propTypes = {};
Banner.defaultProps = {};
export default Banner;
