import styled from "styled-components";
import base from "../../styles/base.style";

export const TestimonialWrap = styled('section')`
    ${base};
    padding-top: 45px;
    background: #DDEAFD;
    @media (max-width: 575px){ 
        padding-bottom: 45px;
    }
    .t-item-wrap{
        padding: 75px 40px 45px;
        box-sizing: border-box;
        @media (max-width: 575px){
            padding: 75px 0 45px; 
        }
    }
`;

export const TestimonialItem = styled('div')`
    ${base};
    padding: 0 65px 25px;
    background: #FFFBFB;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.09);
    @media (max-width: 575px){
        padding: 0 15px 25px;
    }
    .t-header{
        display: flex;
        align-items: flex-end;
        @media (max-width: 575px){
            flex-direction: column;
            align-items: center;
        }
    }
    .t-logo{
        border-radius: 100%;
        margin-right: 20px;
        margin-top: -75px;
        height: 150px;
        width: 150px;
        object-fit: cover;
        @media (max-width: 575px){
            height: 100px;
            width: 100px;
            margin-top: -50px;
        }
    }
    .t-meta{
        h3{
            font-size: 40px;
            line-height: normal;
            margin: 0;
        }
    }
    .t-position {
        font-size: 40px;
        color: #C2BABA;
        margin-top: 15px;
        margin-bottom: 0;
        padding-left: 35px;
        @media (max-width: 575px){
            padding-left: 0;
        }
    }
    .t-des {
        font-size: 20px;
        color: #807373;
        padding-left: 35px;
        @media (max-width: 575px){
            padding-left: 0;
        }
    }
`;
