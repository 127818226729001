import {Div, GlobalHeading, Section} from "../../styles/base.style";
import React, {Component} from "react";
import HomepageDemo from "./HomepageDemo";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

class PrebuildDemoSection extends Component<{}> {
    render() {
        // console.log('PrebuildDemoSection');
        let {...props} = this.props;
        // console.log(props);
        return (
            <Section id="prebuild-demo" pt={108} pb={144}>
            <Div className="container">
                <Div className="row">
                    <Div className="col-md-8 offset-md-2 text-center">
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <GlobalHeading>Stunning Home Page</GlobalHeading>
                        </ScrollAnimation>
                    </Div>
                </Div>
            </Div>
            <Div mt={120} className={(props.prebuild_demo_layout === 'layout-3-col') ? 'container' : "container-fluid"}>
                <Div className="row">
                    <Div className="col-md-12">
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <HomepageDemo {...props} />
                        </ScrollAnimation>
                    </Div>
                </Div>
            </Div>
        </Section>
        );
    }
}

export default PrebuildDemoSection
