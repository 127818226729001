import React from "react";
import anime from "animejs";
import {FilterWrap, HomeDemoMetaWrap, HomeDemoWrap, HomepageDemoContainer, ImgWrap} from "../prebuildDemoSection.style";
import {Div, Heading, Img, Link} from "../../../styles/base.style";
import {Flipped, Flipper} from "react-flip-toolkit";
// import {LazyLoadImage} from "react-lazy-load-image-component";
// import 'react-lazy-load-image-component/src/effects/opacity.css';
// import 'react-lazy-load-image-component/src/effects/black-and-white.css';
// import 'react-lazy-load-image-component/src/effects/blur.css';
import New_Icon from '../../../images/new-item.svg';


class HomepageDemo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            homePageDemo: [],
            homePageTemp: [],
            classActive: 'all',

        };
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        // console.log('componentWillReceiveProps:', nextProps);
        this.setState({...nextProps})
    }

    onStart = (el) => {
        console.log('on start');
    };

    onComplete = (el) => {
        console.log('on complete');
    };

    shouldFlip = (prev, current) => {
        console.log('should flip', prev, current);
    };

    onAppear = (el, index) => {
        console.log('onAppear');
        anime({
            targets: el,
            opacity: [0, 1],
            scale: [0, 1],
            easing: "easeOutSine",
            delay: index * 40,
            duration: 400
        })
    };

    onExit = (el, index, removeElement) => {
        console.log('onExit');
        anime({
            targets: el,
            opacity: 0,
            scale: 0.5,
            easing: "easeOutSine",
            duration: 400,
            delay: index * 40,
            complete: removeElement
        })
    };

    filterHomePage(e, filter) {
        e.preventDefault();
        // console.log(filter);
        const has = Object.prototype.hasOwnProperty;

        // let homePages = this.state.homePageTemp.filter((home, index) => {
        //     console.log(has.call(home.category_filter, filter));
        //     return has.call(home.category_filter, filter)
        // });
        let homePages = this.state.homePageTemp.reduce((dataHomeNew, home, index, dataHome) => {
            let _has = has.call(home.category_filter, filter);
            // console.log(_has, dataHomeNew, home, index);
            if (_has) dataHomeNew[index] = home;

            return dataHomeNew;
        }, []);

        this.setState({homePageDemo: homePages, classActive: filter});
    }

    renderFilterHomePage() {
        let {catFilter} = this.state;
        if (catFilter) {
            catFilter = Object.entries(catFilter);
            // console.log('renderFilterHomePage', catFilter);
            return (
                <FilterWrap display={'flex'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'} mb={75}>
                    {catFilter.map((cat, index) => {
                        let filterClass = ['filter-nav'];
                        if (cat[0]) {
                            filterClass.push(cat[0]);
                            if (cat[0] === this.state.classActive) {
                                filterClass.push('active');
                            }
                        }
                        if (cat[1]) {
                            return (
                                <Link onClick={e => this.filterHomePage(e, cat[0])}
                                      href={'#'}
                                      key={'filter-nav-' + index}
                                      className={filterClass.join(" ")}
                                      data-filter={cat[0]}>
                                {cat[1]}
                            </Link>
                            )
                        }
                        return false;
                    })}
                </FilterWrap>
            );
        }
    }

    renderDemoHomePage() {
        let homePageDemo = this.state.homePageDemo;
        // console.log(homePageDemo);
        if (homePageDemo) {
            return (
                <HomepageDemoContainer className={this.state.prebuild_demo_layout} display={'grid'} gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gridGap={'40px 35px'}>
                    {homePageDemo.map((home, index) => {
                        return <Flipped
                            key={`home-${index}`}
                            flipId={`home-${index}`}
                            spring={'veryGentle'}

                            onStartImmediate={this.onStart}
                            onComplete={this.onComplete}
                            onAppear={this.onAppear}
                            onExit={this.onExit}
                            shouldInvert={this.shouldFlip}
                        >
                            <HomeDemoWrap data-filter={Object.keys(home.category_filter).join(' ')}>
                                {(home.new_label) ?
                                    <Img src={New_Icon} alt={'new icon'} position={'absolute'} top={0} left={-9} zIndex={9} /> : ''}
                                {(home.image_featured) ?
                                    <ImgWrap>
                                        <Link href={(home.link_demo_page.url) ? home.link_demo_page.url : '#'} target={'_blank'}>
                                            <Img src={home.image_featured} alt={home.name_demo_page} />
                                        </Link>
                                    </ImgWrap>
                                    : ''
                                }
                                <HomeDemoMetaWrap>
                                    <Link href={(home.link_demo_page.url) ? home.link_demo_page.url : '#'} target={'_blank'}>
                                        <Heading className={'home-title'} fontWeight={600} fontSize={'24px'} lineHeight={'36px'} color={'white'} mb={'16px'}>
                                            {home.name_demo_page}
                                        </Heading>
                                    </Link>
                                    <Link className={'demo-link'} href={(home.link_demo_page.url) ? home.link_demo_page.url : '#'} target={'_blank'}>VIEW DEMO</Link>
                                </HomeDemoMetaWrap>
                            </HomeDemoWrap>
                        </Flipped>;
                    })}
                </HomepageDemoContainer>
            );
        }
    }

    renderHomePageDemo() {
        return (
            <Flipper flipKey={this.state.homePageDemo}>
                {this.state.disable_filter ? this.renderFilterHomePage() : ''}
                {this.renderDemoHomePage()}
            </Flipper>
        );
    }

    render() {
        // console.log('HomepageDemo');
        // console.log(this.props, this.state);
        const {isLoading, catFilter, homePageDemo, error} = this.props;
        // console.log({isLoading, catFilter, homePageDemo, error});
        // console.log(Object.entries(catFilter));

        return (
            <Div>
                {error ? <p className={'text-center'}>{error}</p> : null}
                {!isLoading ?
                    (this.renderHomePageDemo()) :
                    (<p className={'text-center'}>Loading...</p>)
                }
            </Div>
        )
    }
}

export default HomepageDemo
