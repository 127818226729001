import base from "../../styles/base.style";
import styled from "styled-components";
import BG from '../../images/bg_core.png'

export const CoreFeaturesWrap = styled('section')`
    ${base};
    background-image: url("${BG}");

`;

export const SpecialItem = styled('div')`
    ${base};
    height: 100%;
    transition: .3s;
    cursor: pointer;
    box-shadow: 0 4px 22px rgba(0, 0, 0, 0.25);
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4){
    box-shadow: 0 0 23px rgba(0, 0, 0, 0.12);
    }
    .title{
        text-transform: uppercase;
    }
    &:hover{
        border-color: var(--theme-color);
        box-shadow: 0  4px 22px rgba(0, 0, 0, 0.25);
    }
`;
