import styled, {createGlobalStyle} from "styled-components";
import {
    alignContent,
    alignItems,
    alignSelf,
    background,
    backgroundImage,
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    borderBottom,
    borderColor,
    borderLeft,
    borderRadius,
    borderRight,
    borders,
    borderStyle,
    borderTop,
    borderWidth,
    bottom,
    color,
    compose,
    display,
    flex,
    flexBasis,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    grid,
    gridArea,
    gridAutoColumns,
    gridAutoFlow,
    gridAutoRows,
    gridColumn,
    gridColumnGap,
    gridGap,
    gridRow,
    gridRowGap,
    gridTemplateAreas,
    gridTemplateColumns,
    gridTemplateRows,
    height,
    justifyContent,
    justifyItems,
    justifySelf,
    left,
    letterSpacing,
    lineHeight,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    opacity,
    order,
    overflow,
    position,
    right,
    shadow,
    size,
    space,
    textAlign,
    top,
    typography,
    verticalAlign,
    width,
    zIndex
} from "styled-system";

const base = compose(
    () => ({boxSizing: 'border-box'}),
    space,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    fontSize,
    color,
    typography,
    flex,
    order,
    alignSelf,
    alignItems,
    borders,
    borderColor,
    display,
    fontFamily,
    fontWeight,
    textAlign,
    lineHeight,
    letterSpacing,
    position,
    shadow,
    background,
    grid,
    //======
    width, height, minWidth, minHeight, maxWidth, maxHeight, size, verticalAlign, display, overflow, // color
    opacity, // typography
    fontSize, fontFamily, fontWeight, lineHeight, textAlign, fontStyle, letterSpacing, // flexbox
    alignItems, alignContent, justifyItems, justifyContent, flexWrap, flexDirection, flex, flexGrow, flexShrink, flexBasis, justifySelf, alignSelf, order, // grid
    gridGap, gridColumnGap, gridRowGap, gridColumn, gridRow, gridAutoFlow, gridAutoColumns, gridAutoRows, gridTemplateColumns, gridTemplateRows, gridTemplateAreas, gridArea, // border
    borderWidth, borderStyle, borderColor, borderTop, borderRight, borderBottom, borderLeft, borderRadius, // background
    backgroundImage, backgroundSize, backgroundPosition, backgroundRepeat, // position
    zIndex, top, right, bottom, left
);
base.propTypes = {
    ...space.propTypes,
    ...width.propTypes,
    ...minWidth.propTypes,
    ...maxWidth.propTypes,
    ...height.propTypes,
    ...minHeight.propTypes,
    ...maxHeight.propTypes,
    ...fontSize.propTypes,
    ...color.propTypes,
    ...typography.propTypes,
    ...flex.propTypes,
    ...order.propTypes,
    ...alignSelf.propTypes,
    ...borders.propTypes,
    ...borderColor.propTypes,
    ...display.propTypes,
    ...fontFamily.propTypes,
    ...fontWeight.propTypes,
    ...textAlign.propTypes,
    ...lineHeight.propTypes,
    ...letterSpacing.propTypes,
    ...position.propTypes,
    ...shadow.propTypes,
    ...background.propTypes,
    ...grid.propTypes,
};
const Button = styled('button')(base);
const Section = styled('section')(base);
const Header = styled('header')(base);
const Div = styled('div')(base);
const Heading = styled('h3')(base);
const Text = styled('p')(base);
const Link = styled('a')(base);
const Img = styled('img')(base);


const GlobalStyle = createGlobalStyle` 
:root{
--theme-color: ${props => props.theme.colors.accentColor}; 
--second-color: ${props => props.theme.colors.secondColor}; 
}
body{
font-family: ${props => props.theme.fontFamily}; 
overflow-x: hidden;
}
img { 
    max-width: 100%;
}

.particles{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}
.container-full{
    padding-right: 15px;
    padding-left: 15px;
}
.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.modal-video{
    .modal-video-body{
        max-width: 1200px;
        .modal-video-movie-wrap {
            @media (max-width: 991px) {
                max-width: calc(100% - 80px);
                margin: auto;
            }
        }
    }
}


@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

 .fadeIn{
  animation: fadeIn .35s forwards;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

@keyframes fadeOut {
  0% { opacity: 1}
  100% { opacity: 0; }
}

 .fadeOut{
  animation: fadeOut .3s forwards;
  animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

.text-below-global-heading{
    font-size: 25px;
    line-height: normal;
    color: #939393;
    text-align: center;
    padding-top: 65px;
}

@-webkit-keyframes pulse { from { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
    50% { -webkit-transform: scale3d(1.05, 1.05, 1.05); transform: scale3d(1.05, 1.05, 1.05); }
    to { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); } }
@keyframes pulse { from { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
    50% { -webkit-transform: scale3d(1.05, 1.05, 1.05); transform: scale3d(1.05, 1.05, 1.05); }
    to { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); } }
.pulse { -webkit-animation-name: pulse; animation-name: pulse; }
.animated { -webkit-animation-duration: 1s; animation-duration: 1s; -webkit-animation-fill-mode: both; animation-fill-mode: both; }

.animated.infinite { -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; }

`;

export const GlobalHeading = styled('h3')`
    ${base};
    position: relative;
    font-size: 40px;
    line-height: normal;
    color: #615050;
    text-align: center;
    display: inline-block;
    margin: auto;
    &:before{
        content: '';
        position: absolute;
        width: 50%;
        height: 3px;
        background: var(--theme-color);
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
    }
`
;


export default base;
export {GlobalStyle, Button, Section, Header, Div, Heading, Text, Link, Img};
