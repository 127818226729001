import styled from "styled-components";
import base from "../../styles/base.style";

export const BannerWrap = styled('section')`
    ${base}; 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    .layer{
        position: absolute;top: 0;
        box-shadow: 0 0 117px 0 rgba(0, 0, 0, 0.38);
        transition: .3s ease;
        &.layer-1{
            position: relative;
            left: -54px;
            box-shadow: none;
        }
        &.logo-d{
            left: 40%;
            top: 65px;
            transform: translateX(-35%);
            width: 400px;
            box-shadow: none;
        }
        &.layer-2{
            left: 60%;
            transform: translateX(-35%);
        }
        &.layer-4{
            right: 140px;
            top: 120px;
        }
        &.layer-3,
        &.layer-5{
            right: 0;
        }
        &.layer-5{
            //bottom: 5px;
            top: calc(100% - 142px);
        }
    } 
    .bn-title{
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        color: #FFFDFD;
        margin-bottom: 30px;
        @media (max-width: 1024px){
            font-size: 20px;
            line-height: 26px;
        } 
    }
    .bn-btn{  
        font-weight: 500;
        font-size: 16px;
        line-height: 1; 
        color: #FFFFFF;
        display: inline-flex;
        padding: 20px 65px;
        background: #131313;
        border-radius: 6px;
        text-decoration: none;
        text-transform: uppercase;
        transition: .3s;
        box-shadow: 1px 10px 10px 0 rgba(13, 13, 13, 0.21); 
        @media screen and (max-width: 767px)   { 
            padding: 20px 40px;
        }
        &:hover{
            background: var(--theme-color);
        }
    }
    .el-demo-wrap{
        .number {
            font-size: 50px;
            line-height: 1;
            font-weight: bold;
        }
        .text{
            font-size: 15px;
            line-height: 25px;
            color: white;
        }
        .el-animation{
            position:absolute;
            top: -20px;
            left: -20px;
            width: calc(100% + 40px);
            height: calc(100% + 40px);
            border-radius: 100%;
            z-index: -1;
            pointer-events: none;
        }
        .element-wrap,
        .demo-wrap{
            display: flex;
            flex-direction: column;
            width: 208px;
            height: 208px;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            position: relative;
            z-index: 1;
            cursor: pointer;
            @media screen and (max-width: 1199px) {
                width: 180px;
                height: 180px;
            }
            @media screen and (max-width: 480px)   {
                width: 150px;
                height: 150px;
            }
        }
        .element-wrap {
            background: #139d7d;
            position: absolute;
            top: -25%;
            left: -143px;
            @media screen and (max-width: 1440px) and (min-width: 1200px){
                left: 0;
                top: -20%;
            }
            @media screen and (max-width: 1199px) and (min-width: 992px){
                left: 0;
                top: -25%;
            }
            @media screen and (max-width: 991px) and (min-width: 768px)  {
                left: 0;
                top: -10%;
            }
            @media screen and (max-width: 767px)   {
                left: calc(50% - 180px / 2 );
                top: -5%;
                transform: translateX(-50%);
            }
            @media screen and (max-width: 480px)   {
                left: calc(50% - 150px / 2 );
                top: 10%;
            }
            .el-animation{
                background: rgba(19,157,125,0.25);
            }
            .number {
                color: #a2ff72;
            }
        }
        .demo-wrap {
            background: #0a6edd; 
            position: absolute;
            top: -50%;
            left: 40px;
            @media screen and (max-width: 1440px) and (min-width: 1200px){
                left: 180px;
                top: -40%;
            }
            @media screen and (max-width: 1199px) and (min-width: 992px){
                left: 160px;
                top: -30%;
            }
            @media screen and (max-width: 991px) and (min-width: 768px) {
                left: 160px;
                top: -15%;
            }
            @media screen and (max-width: 767px)  {
                left: calc(50% - 15px);
                top: 15%;
            }
            @media screen and (max-width: 480px)   {
                left: calc(50% - 5px); top: 25%;
            }
            .el-animation{
                background: rgba(10,110,221,0.25);
            }
            .number {
                color: #fff600;
            }
        }
    }
`;

export const BgBannerWrap = styled('div')`
    ${base}; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img{
        height: 100%;
        object-fit: cover;
        width: 100%;
    } 
`;

